import { Tooltip } from '@mui/material';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import React from 'react';
import { ICON_PLACEHOLDER_STYLE } from '../../constants';

//Image with Label for key feature tab
export function KeyFeatureLabel({ icon, feature, label }) {
  return (
      <li>
        {icon ? <img src={icon} alt="Icon" /> : <HideImageOutlinedIcon style={feature ? ICON_PLACEHOLDER_STYLE.FeatureKeyFeatureLabel : ICON_PLACEHOLDER_STYLE.KeyFeatureLabel} />}
        {label}
      </li>
  )
}

//Image with Label for key feature tab with tooltip
export function KeyFeatureLabelTooltip({ title, icon, label }) {
  return (
    <Tooltip title={title} arrow PopperProps={{ style: { zIndex: 1 } }}>
      <li>
        {icon ? <img src={icon} alt="Icon" /> : <HideImageOutlinedIcon style={ICON_PLACEHOLDER_STYLE.KeyFeatureLabel} />}
        {label}
      </li>
    </Tooltip>
  )
}

export const VerticalLogoWithHeading = ({ logo, heading, text }) => {
  return (
    <div className="growth-item">
      {logo ? <img src={logo} alt="Icon" /> : <HideImageOutlinedIcon style={ICON_PLACEHOLDER_STYLE.VerticalLogoWithHeading} />}
      <h3>{heading}</h3>
      <p className='pb-0'>{text}</p>
    </div>
  )
}

export const TextWithHeading = ({ className, heading, text, textClassName }) => {
  return (
    <div className={className}>
      <h3>{heading}</h3>
      <p className={textClassName}>{text}</p>
    </div>
  )
}

export const KeySpanLogo = ({ logo, text }) => {
  return (
    <span>
      {logo ? <img src={logo} alt="Icon" /> : <HideImageOutlinedIcon style={ICON_PLACEHOLDER_STYLE.KeySpanLogo} />}
      <span>
        {text}
      </span>
    </span>
  )
}
