import { RFF_PROJECTS, RFF_PROPTECH_LISTING } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { Store } from "../../../../store/configureStore";
import { RFF_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { apiTimeoutTime } from "../../../constants";
import { companyProfileTabRFFDTO } from "../../dtos/companyProfileDTO";
import { deployAndUseDTO } from "../../dtos/deployAndUseTab";
import { designReviewTabRFFDTO } from "../../dtos/designReviewRFF";
import { marketInsightsTabRFFDTO } from "../../dtos/marketInsightsRFF";
import { marketTabRFFDTO } from "../../dtos/marketRFF";
import { numbersTabRFFDTO } from "../../dtos/numbersRFF";
import { overviewTabRFFDTO } from "../../dtos/overviewRFF";
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO";

export const getProptechDetailsAPI = (proptechCompanyDetailsId) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}`)
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFProptechDetails,
        payload: proptechDetailsDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { proptechDetails: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { proptechDetails: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((err) => {
      handleError(err);
      return err?.status;
    });
};

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/overview`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFOverviewDetails,
        payload: overviewTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { overview: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { overview: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      setIsLoading(false);
      return err?.status;
    });
};

export const getOfferingAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/market`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFMarketDetails,
        payload: marketTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { market: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { market: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getTrackRecordAPI = (proptechCompanyDetailsId, setIsLoading) => {
  getPastProjectsData(proptechCompanyDetailsId);
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/numbers`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFNumbersDetails,
        payload: numbersTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { numbers: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { numbers: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getDeployAndUseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/deployUse`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFDeployAndUseDetails,
        payload: deployAndUseDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { deployAndUse: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { deployAndUse: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getDesignReviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/designReview`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFDesignReviewDetails,
        payload: designReviewTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { designReview: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { designReview: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getMarketInsightsAPI = (
  proptechCompanyDetailsId,
  setIsLoading
) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/marketInsights`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFMarketInsightsDetails,
        payload: marketInsightsTabRFFDTO(res?.data?.data?.marketInsight),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { marketInsights: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { marketInsights: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getCompanyProfileAPI = (
  proptechCompanyDetailsId,
  setIsLoading
) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/companyProfile`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFCompanyProfileDetails,
        payload: companyProfileTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { companyProfile: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { companyProfile: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getKeyAttributesData = () => {
  return RFF_API()
    .get(`${RFF_PROJECTS}/keyAttributes`)
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFKeyAttributesDetails,
        payload: res?.data?.data,
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      // handleError(err)
      return err?.status;
    });
};

export const getPastProjectsData = (proptechCompanyDetailsId) => {
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING}/${proptechCompanyDetailsId}/pastProjects`)
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFPastProjectsDetails,
        payload: res?.data?.data,
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      return err?.status;
    });
};
