import { RFF_PROPTECH_LISTING } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { Store } from "../../../../store/configureStore";
import { PUBLIC_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { apiTimeoutTime } from "../../../constants";
import { deployAndUseDTO } from "../../dtos/deployAndUseTab";
import { marketInsightsTabRFFDTO } from "../../dtos/marketInsightsRFF";
import { marketTabRFFDTO } from "../../dtos/marketRFF";
import { numbersTabRFFDTO } from "../../dtos/numbersRFF";
import { overviewTabRFFDTO } from "../../dtos/overviewRFF";
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO";

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/overview/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFOverviewDetails,
        payload: overviewTabRFFDTO(
          res?.data?.data?.proptechCompanyOverviewDetails
        ),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFProptechDetails,
        payload: proptechDetailsDTO(res?.data?.data?.proptechCompanyDetails),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { overview: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { overview: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      setIsLoading(false);
      return err?.status;
    });
};

export const getOfferingAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/market/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFMarketDetails,
        payload: marketTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { market: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { market: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getTrackRecordAPI = (proptechCompanyDetailsId, setIsLoading) => {
  getPastProjectsData(proptechCompanyDetailsId);
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/numbers/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFNumbersDetails,
        payload: numbersTabRFFDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { numbers: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { numbers: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getDeployAndUseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/deployUse/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFDeployAndUseDetails,
        payload: deployAndUseDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { deployAndUse: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { deployAndUse: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getMarketInsightsAPI = (
  proptechCompanyDetailsId,
  setIsLoading
) => {
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/marketInsights/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFMarketInsightsDetails,
        payload: marketInsightsTabRFFDTO(res?.data?.data?.marketInsight),
      });
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFApiTimeout,
        payload: { marketInsights: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.RFFProptechDetails.setRFFApiTimeout,
          payload: { marketInsights: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getPastProjectsData = (proptechCompanyDetailsId) => {
  return PUBLIC_API()
    .get(`${RFF_PROPTECH_LISTING}/pastProjects/${proptechCompanyDetailsId}`)
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechDetails.setRFFPastProjectsDetails,
        payload: res?.data?.data,
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      return err?.status;
    });
};
