import { Store } from "../../store/configureStore";

export function dispatch(type, payload) {
  Store.dispatch({ type, payload });
}

export function dispatchNoPayload(type) {
  Store.dispatch({ type });
}

