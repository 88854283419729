import { RFF_API } from "../../../../utils/utility/API-call";
import {
  RFF_REGIONS_API,
  RFF_ACTIVEUSERSLIST_API,
  RFF_INVESTMENT_STAGES_API,
} from "../../../../constants/APIs";
import { handleError } from "../../../../utils/utility/snackbar";

export const getRegionList = () => {
  return RFF_API()
    .get(RFF_REGIONS_API)
    .then((res) => {
      if (res.data.data) {
        return res.data.data;
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error);
    });
};

export const getInvestmentStagesList = () => {
  return RFF_API()
    .get(RFF_INVESTMENT_STAGES_API)
    .then((res) => {
      if (res.data.data) {
        return res.data.data;
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error);
    });
};

export const getActiveUsers = () => {
  return RFF_API()
    .get(RFF_ACTIVEUSERSLIST_API)
    .then((res) => {
      if (res?.data && res?.data?.code === 200) {
        return res?.data?.data;
      }
      handleError(res?.data);
    })
    .catch((error) => {});
};
