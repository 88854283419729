import React from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import Chart from "../../Chart";
import { KeyFeatureLabel } from "../../Label";
import { chartData, chartLabels } from "../../../utils/chartData";
import { Radar_Chart_Labels } from "../../../constants";

export default function OverviewTab() {
  // Store
  const { overviewDetails } = useSelector((state) => state.rffProptechDetails);
  const { id } = useSelector((state) => state.publicProptechUser);

  return (
    <>
      {overviewDetails ? (
        <div
          className={`tabpanel-wrapper-place overview-tab ${
            id && "!max-w-full"
          }`}
        >
          {/*Overview details block */}
          <div className="left_col-place">
            <h2>Details</h2>
            <p>
              {overviewDetails?.proptechCompanyDetails?.technologyProductAdmin}
            </p>
            {overviewDetails?.proptechCompanyDetails?.founderPitchVideo && (
              <div className="video-wrapper">
                <ReactPlayer
                  controls
                  url={
                    overviewDetails?.proptechCompanyDetails?.founderPitchVideo
                  }
                  width="100%"
                  height="100%"
                />
              </div>
            )}
            {/*Sectors, use cases, tech offerings block */}
            <div className="detail-sector-wrapper">
              <div className="detail-sectors">
                <div className="flex items-start">
                  <h2 className="pr-4">Sectors</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisSector1?.icon}
                    label={overviewDetails?.proptechAnalysisSector1?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisSector2?.icon}
                    label={overviewDetails?.proptechAnalysisSector2?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisSector3?.icon}
                    label={overviewDetails?.proptechAnalysisSector3?.name}
                  />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className="flex items-start">
                  <h2 className="pr-4">Use cases</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisUseCase1?.icon}
                    label={overviewDetails?.proptechAnalysisUseCase1?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisUseCase2?.icon}
                    label={overviewDetails?.proptechAnalysisUseCase2?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisUseCase3?.icon}
                    label={overviewDetails?.proptechAnalysisUseCase3?.name}
                  />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className="flex items-start">
                  <h2 className="pr-4">Tech offering</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisTechOffering1?.icon}
                    label={overviewDetails?.proptechAnalysisTechOffering1?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisTechOffering2?.icon}
                    label={overviewDetails?.proptechAnalysisTechOffering2?.name}
                  />
                  <KeyFeatureLabel
                    icon={overviewDetails?.proptechAnalysisTechOffering3?.icon}
                    label={overviewDetails?.proptechAnalysisTechOffering3?.name}
                  />
                </ul>
              </div>
            </div>
          </div>
          {/*Overview Chart block */}
          <div className="right_col-place" onClick={(e) => e.stopPropagation()}>
            <div className="flex items-start">
              <h2 className="pr-4">{Radar_Chart_Labels.overview}</h2>
            </div>
            <div className="chart-wrapper">
              <Chart
                items={chartData.map((val) => overviewDetails[val])}
                labels={chartLabels}
                step={2}
                label={"Rating"}
                maximumValue={10}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
