import { RFF_PLAN_DETAILS_API } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { RFF_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { navTabsTBDTO } from "../../dtos/navigationTabsDTO";

export const getPlansData = () => {
  RFF_API()
    .get(RFF_PLAN_DETAILS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(
          Actions.RFFUser.SetNavTabs,
          navTabsTBDTO(res?.data?.data?.proptechTabsAssigned)
        );
        dispatch(
          Actions.RFFUser.SetActivePlan,
          res?.data?.data?.rffPlanDetails
        );
        dispatch(Actions.RFFUser.SetCountDetails, res?.data?.data);
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((err) => {
      handleError(err);
    });
};
