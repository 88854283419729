// Reducers
import loader from "./reducers/loader";
import snackbar from "./reducers/snackbar";
import rffAuth from "./reducers/rffAuth";
import rffUser from "./reducers/rffUser";
import rffProptechs from "./reducers/rffProptechs";
import rffForYou from "./reducers/rffForYou";
import rffProptechDetails from "./reducers/rffProptechDetails";
import publicProptechUser from "./reducers/publicProptechUser";

// Utility Packages
import { combineReducers } from "redux";

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  loader,
  snackbar,
  rffAuth,
  rffUser,
  rffProptechs,
  rffForYou,
  rffProptechDetails,
  publicProptechUser,
});
