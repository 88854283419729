import React from "react";
import { useNavigate } from "react-router-dom";
import PTButton from "../../../components/GenericComponents/PTButton";
import Logo from "../../../assets/Images/PLACE_White logo.png";
import "./interest.scss";

// 235 * 79
export default function Interest() {
  const navigate = useNavigate();

  return (
    <div className="thanks-page-place flex items-center md:min-h-screen flex-wrap">
      <div className="background-blue"></div>
      <div className="thanks-wrapper">
        <div className="thanks-inner">
          <img
            src={Logo}
            alt=""
            className="cursor-pointer"
            onClick={() => navigate("/", { replace: true })}
            height={79}
            width={235}
          />
          <h2>Thanks for your interest</h2>
          <p>
            Your application is being reviewed by our team and we’ll be in touch
            via email shortly.
          </p>
          <p>In the meantime, why not check out our website?</p>
          <PTButton
            label="Discover the PTC"
            className="fill-blue btn"
            size="large"
            handleOnClickEvent={() =>
              (window.location.href = "http://theproptechconnection.com")
            }
          />
        </div>
      </div>
      <div className="not-have-account">
        <p>For more information, contact us at</p>
        <a href="mailto:admin@theproptechconnection.com" className="block">
          admin@theproptechconnection.com
        </a>
      </div>
    </div>
  );
}
