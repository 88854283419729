import moment from "moment";
import { URL_REGEX } from "../../constants/General";

export const trimQuery = (query) => {
  Object.keys(query).forEach(k => query[k] = typeof query[k] == 'string' ? query[k].trim() : query[k])
  return query
}

export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return `$${(num / 1000).toFixed(1)}k`; // convert to K for number from > 1000 < 1 million 
  } else if (num >= 1000000) {
    if (num === 10000000) {
      return `$${(num / 1000000).toFixed(1)}m+`; // convert to M for number from > 1 million 
    } else {
      return `$${(num / 1000000).toFixed(1)}m` // convert to M for number from > 1 million 
    }
  } else if (num < 900) {
    return `$${num}`; // if value < 1000, nothing to do
  }
}
export function numFormatterProject(num) {
  if (num > 999 && num < 1000000) {
    return `$${(num / 1000).toFixed()}k`; // convert to K for number from > 1000 < 1 million 
  } else if (num >= 1000000) {
    if (num === 10000000) {
      return `$${(num / 1000000).toFixed()}m+`; // convert to M for number from > 1 million 
    } else {
      return `$${(num / 1000000).toFixed()}m` // convert to M for number from > 1 million 
    }
  } else if (num < 900) {
    return `$${num}`; // if value < 1000, nothing to do
  }
}


export function formatTime(time, format) {
  return moment.tz(time, "UTC").format(format)
}

export function urlValidate(url) {
  return URL_REGEX.test(url)
}

export const stringAvatar = (name) => {
  if (name)
    return {
      children: `${name.split(" ")[0][0]}${
        name.split(" ").length > 1 ? name.split(" ")[1][0] : ``
      }`,
    };
};

export const extractFilenameFromURL = (url) => {
  // Split the URL by the slash (/) character
  var parts = url.split("/");

  // Get the last part of the URL, which should be the filename
  var filename = parts[parts.length - 1];

  // If the filename contains a query string, remove it
  if (filename.indexOf("?") !== -1) {
    filename = filename.split("?")[0];
  }

  // Return the extracted filename
  return filename;
}
