import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import mapIcon from "../../../../assets/Icons/ic-map-place.svg";
import {
  getOverviewAPI,
  getOfferingAPI,
  getTrackRecordAPI,
  getMarketInsightsAPI,
  getDeployAndUseAPI,
} from "../../../utils/middleware/PublicProptechDetails";
import OfferingTab from "../../../components/ProptechDetails/OfferingTab";
import CompanyLogo from "../../../components/CompanyLogo";
import OverviewTab from "../../../components/ProptechDetails/OverviewTab";
import TrackRecordTab from "../../../components/ProptechDetails/TrackRecordTab";
import MarketInsightTab from "../../../components/ProptechDetails/MarketInsightTab";
import DeployAndUse from "../../../components/ProptechDetails/DeployAndUse";
import Loader from "../../../../components/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Actions } from "../../../../store/actions";
import { dispatch, dispatchNoPayload } from "../../../../utils/utility/store";
import { Tooltip } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="h-full">
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PublicPlaceProptechDetails() {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { proptechDetails } = useSelector((state) => state.rffProptechDetails);
  const rffProptechDetails = useSelector((state) => state.rffProptechDetails);
  const publicProptechUser = useSelector((state) => state.publicProptechUser);

  useEffect(() => {
    if (publicProptechUser?.isVerified) {
      setIsVerified(true);
      setActiveTabData(0, publicProptechUser?.id, rffProptechDetails);
    } else if (location?.state?.isVerified) {
      let { isVerified, id } = location?.state;
      dispatch(Actions.PublicProptechUser.SetIsVerified, isVerified);
      dispatch(Actions.PublicProptechUser.SetId, id);
      setIsVerified(true);
      setActiveTabData(0, id, rffProptechDetails);
    } else {
      navigate(`/${location.pathname.split("/")[1]}/${params.id}`, {
        replace: true,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setActiveTabData(tab, id, rffProptechDetails) {
    switch (tab) {
      case 0:
        !rffProptechDetails?.overviewDetails &&
          getOverviewAPI(id, setIsLoading);
        break;

      case 1:
        !rffProptechDetails?.marketDetails && getOfferingAPI(id, setIsLoading);
        break;

      case 2:
        !rffProptechDetails?.numbersDetails &&
          getTrackRecordAPI(id, setIsLoading);
        break;

      case 3:
        !rffProptechDetails?.marketInsightsDetails &&
          getMarketInsightsAPI(id, setIsLoading);
        break;

      case 4:
        !rffProptechDetails?.deployAndUseDetails &&
          getDeployAndUseAPI(id, setIsLoading);
        break;

      default:
        !rffProptechDetails?.overviewDetails &&
          getOverviewAPI(id, setIsLoading);
        break;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTabData(newValue, params.id, rffProptechDetails);
  };
  useEffect(
    () => () =>
      dispatchNoPayload(Actions.RFFProptechDetails.RFFProptechDetailsReset),
    []
  );

  return (
    <>
      {isLoading && <Loader />}
      {isVerified === true && (
        <>
          <div className="no-print w-full h-full flex flex-col proptech-detail-wrapper-place overflow-y-auto md:overflow-inherit">
            <div className="top-menu-place bg-[#F7F7F7]">
              <div className="left_col-place">
                <div className="nav-top overflow-hidden flex">
                  <CompanyLogo
                    companyLogo={proptechDetails?.companyLogo}
                    name={proptechDetails?.proptechCompany?.name}
                  />
                  <div className="card-info">
                    <h2>
                      {proptechDetails?.proptechCompany?.name || "No Name"}
                    </h2>
                    <ul className="listing-item-place badge-item">
                      <Tooltip title={"Region"} arrow>
                        <li>
                          <img src={mapIcon} alt="Icon" />
                          {proptechDetails?.region?.name || "-"}
                        </li>
                      </Tooltip>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="protech-detail-inner-place">
              <div className="tab-wrapper-place">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box className="tab-heading-wrapper md:sticky lg:top-0  z-10">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="proptech tabs"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab label="Overview" {...a11yProps(0)} />
                      <Tab label="Offering" {...a11yProps(1)} />
                      <Tab label="Track record" {...a11yProps(2)} />
                      <Tab label="Market insights" {...a11yProps(3)} />
                      <Tab label="Deploy + use" {...a11yProps(4)} />
                    </Tabs>
                  </Box>
                  <div className="relative md:flex md:items-start">
                    <div className="tab-panels-place !max-w-full mb-2">
                      <TabPanel value={value} index={0}>
                        <OverviewTab />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <OfferingTab />
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <TrackRecordTab />
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <MarketInsightTab />
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <DeployAndUse />
                      </TabPanel>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
