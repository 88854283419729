export function activeUsersDTO(response) {
  let activeUsers = [];
  if (response && response?.length) {
    activeUsers = response?.map((data) => {
      return {
        id: data?.id || 0,
        name: data?.id || '',
        email: data?.email || '',
        title: data?.email || null
      }
    })
  }

  return activeUsers;
}

export function userListDTO(data) {
  let usersList = [];
  if (data && data.length) {
    usersList = data.map((user) => {
      return {
        id: user?.id || 0,
        name: user?.name || null,
        email: user?.email || null,
        status: user?.status !== null ? user.status : false,
        lastLoginAt: user?.lastLoginAt || null,
        createdAt: user?.createdAt || null,
      }
    });
  }

  return usersList;
}