export const StatusCode = {
	BadRequest: 400,
	UnAuthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	AlreadyExists: 409,
	InternalServerError: 500,
};

export const ResponseStatusCode = {
	Created: 201,
	Success: 200,
};

export const MessageType = {
	Error: "ERROR",
	Success: "SUCCESS",
};

export const NotificationModalStyle = {
	elevation: 0,
	sx: {
		overflow: "visible",
		filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
		mt: 1.5,
		"& .MuiAvatar-root": {
			width: 320,
			height: 320,
			ml: -0.5,
			mr: 1,
		},
		"&:before": {
			content: '""',
			display: "block",
			position: "absolute",
			top: 0,
			right: 14,
			width: 10,
			height: 10,
			bgcolor: "background.paper",
			transform: "translateY(-50%) rotate(45deg)",
			zIndex: 0,
		},
	},
};

export const NotificationIconStyle = { width: 40, height: 40, ml: 2 };

export const NotificationTransformOrigin = {
	horizontal: "right",
	vertical: "top",
};

export const NotificationAnchorOrigin = {
	horizontal: "right",
	vertical: "bottom",
};

export const DateFormat = {
	DayMonthFormat: "DD MMM YYYY", //19 Oct 2022
	DayMonthFormat2: "dddd, DD MMMM YYYY", //Wednesday, 19 October 2022
	DayMonthFormat3: "hh:mm a DD MMM YYYY", // 01:19 PM 05 AUG 2022
	DayMonthFormat4: "HH:mm a dddd, DD MMM YYYY", // 01:19 PM Wednesday, 05 AUG 2022
	ISODateFormat: "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]",
};

// eslint-disable-next-line no-useless-escape
export const URL_REGEX =
	/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const TooltipText = {
	manageUserStatus: "Manage whether users accounts are active or not",
	manageUserInfo: "Only primary user can remove users",
	investorProfile: "Key features of business model and go to market strategy",
	companyRegion: "Region",
	companyCountry: "Country",
	revenueRange: "Amount raising",
	employeesCount: "Employees",
	investmentRound: "Investment Round",
	investmentStage: "Investment Stage",
	watchlist: "Put this company on your Watchlist",
	share: "Share",
	comment: "Comment",
	sectors: "Sectors operated in",
	usecases: "Product/service use case",
	techOffering: "Product/service technology offering",
	overviewRadar:
		"PTC analysed high level overview of business and related megatrend thematics.",
	chargingModel: "Charging and pricing models",
	techBuyer: "Client types",
	valueKPI: "Product/service value key performance indicators",
	traction:
		"The numbers + details below have been provided by the Company. This is for information purposes only. The PTC have not verified this information.",
	raiseOverview:
		"The numbers + details below have been provided by the Company. This is for information purposes only. The PTC have not verified this information.",
	marketRadar: `This radar explains why the company's solution is potentially a good one with regards to the market opportunity.`,
	growthBlockers: "Concerns blocking growth",
	numbersRadar: "PTC rated traction points using industry benchmarks.",
	alignment: "Possible alignments with investor styles and traits",
	raiseRadar: "PTC evaluated key business state considerations",
	teamSkills: "Collective team experience and skills",
	keyHireMappings: "Departments in which new key hires have been made",
	designRaviewRadar: "We Discover design rated product design elements",
	trends: "PTC identified market trends",
	predictions: "PTC identified market predictions",
	growth: "PTC identified potential areas of growth",
	whereFutureGoing:
		"PTC futurist analysis considering market trends and predictions",
	keyMetrics: "Relevant market and industry metrics",
	needToKnow: "Pivotal market insights",
	assetLifecycle: "Key components of asset lifecycle",
	problem: "Key problems facing business",
	valueProposition: "Product/service value key performance indicators",
	offering: "Raise offering information",
	whyGoodFit: "Possible alignments with investor styles and traits",
	defensibilityAndTiming:
		"Key features that contribute to defensibility and timing",
	businessModelAndGoToMarket:
		"Key features of business model and go to market strategy",
	customersAndValidation:
		"Key customer information and serving validation points",
	previousDay: "Past meetings",
	upcomingDay: "Upcoming meetings",
	presentDay: "Present day",
};

export const HelpSectionLinks = {
	explainerVideo: "https://vimeo.com/734214160/89932e4387",
	contactUs: "ksa@theproptechconnection.com",
	contactSupport: "admin@theproptechconnection.com",
	// faqs: "https://www.theproptechconnection.com/faqs",
	faqs: "https://www.theproptechconnection.com/saudi-faqs",
	abouthThePtc: "http://theproptechconnection.com",
	help: "",
};

export const HelpMenuPaperProps = {
	elevation: 0,
	sx: {
		overflow: "visible",
		filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
		mt: 1.5,
		"& .MuiAvatar-root": {
			width: 32,
			height: 32,
			ml: -0.5,
			mr: 1,
		},
		"&:before": {
			content: '""',
			display: "block",
			position: "absolute",
			top: 0,
			right: 14,
			width: 10,
			height: 10,
			bgcolor: "background.paper",
			transform: "translateY(-50%) rotate(45deg)",
			zIndex: 0,
		},
	},
};

export const GOOGLE_MAP_STYLE = [
	{
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#d9d9d9",
			},
		],
	},
	{
		elementType: "labels",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#f5f5f5",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative.land_parcel",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative.land_parcel",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#bdbdbd",
			},
		],
	},
	{
		featureType: "administrative.neighborhood",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "poi",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#faf6ee",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "road",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#757575",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry",
		stylers: [
			{
				color: "#dadada",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#616161",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
	{
		featureType: "transit",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "transit.line",
		elementType: "geometry",
		stylers: [
			{
				color: "#faf6ee",
			},
		],
	},
	{
		featureType: "transit.station",
		elementType: "geometry",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#c9c9c9",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9e9e9e",
			},
		],
	},
];

export const meetingStatus = {
	pending: 0,
	completed: 1,
	cancelled: 2,
	unattended: 3,
	inProgress: 4,
	paymentFailed: 5,
};

export const contactDetails = [
	{ textLabel: "Company Name", textValue: "companyName" },
	{ textLabel: "Type", textValue: "userType" },
	{ textLabel: "Business email", textValue: "email" },
	{ textLabel: "Business phone", textValue: "phone" },
	{ textLabel: "LinkedIn url", textValue: "linkedInURL" },
	{ textLabel: "Country", textValue: "countryName" },
	{ textLabel: "Language", textValue: "languageName" },
];
