import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import PTButton from "../../../components/GenericComponents/PTButton";
import LeftLogo from "../../../components/LeftLogo";
import PTInput from "../../../components/GenericComponents/PTInput";
import { startLoading, stopLoading } from "../../../utils/utility/loader";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { RFF_RESET_PASSWORD_API } from "../../../constants/APIs";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "../../../components/ChangePassword/ChangePassword.scss";

export default function ResetPassword() {
  const navigate = useNavigate();
  // State
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required")
        .min(6, "Password is too short - should be 6 chars minimum.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
          "Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)"
        ),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Password don't match"),
    }),
    onSubmit: (values, { resetForm }) => {
      resetPassword(
        {
          forgotPasswordToken: pathName[1],
          password: values.password,
        },
        resetForm
      );
    },
  });

  const location = useLocation();
  const pathName = location?.search.split("=");

  const resetPassword = (payload, resetForm) => {
    startLoading();
    axios
      .put(RFF_RESET_PASSWORD_API, payload)
      .then((res) => {
        stopLoading();
        resetForm({ values: "" });
        handleSuccess(res);
        navigate("/login", { replace: true });
      })
      .catch((error) => {
        stopLoading();
        handleError(error);
      });
  };

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="justify-start flex-col">
            <div className="text-wrapper">
              <h2 className="p-0">Reset Password</h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper p-0">
                <div
                  className={
                    "form-group " +
                    (formik.touched.password && formik.errors.password
                      ? "error-parent"
                      : "") +
                    (!formik.errors.password && formik.values.password
                      ? "success-attempt-password"
                      : "")
                  }
                >
                  <PTInput
                    id="password"
                    name="password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    label="New Password"
                    fullWidth
                    className="form-control"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    icon={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={() =>
                            setShowNewPassword(!showNewPassword)
                          }
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span className="error-txt">{formik.errors.password}</span>
                  )}
                </div>
                <div className="password-requirement">
                  <p>Password must contain at least:</p>
                  <ul>
                    <li>1 uppercase letter</li>
                    <li>1 number</li>
                    <li>1 special character</li>
                  </ul>
                </div>
                <div
                  className={
                    "form-group " +
                    (formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "error-parent"
                      : "") +
                    (!formik.errors.confirmPassword &&
                    formik.values.confirmPassword
                      ? "success-attempt-password"
                      : "")
                  }
                >
                  <PTInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    label="Confirm New Password"
                    fullWidth
                    className="form-control"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    icon={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <span className="error-txt">
                        {formik.errors.confirmPassword}
                      </span>
                    )}
                </div>
                <PTButton
                  label={"Submit"}
                  type="submit"
                  className="mt-2 normal-case fill-blue btn"
                  size="large"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
