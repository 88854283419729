import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../../../assets/Icons/ic-search.svg";
import { Close } from "@material-ui/icons";
import { useTheme, useMediaQuery } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginLeft: 0,
  height: "32px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "12px",
    marginTop:"8px"
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  paddingLeft: "4px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  fontFamily: "inherit",

  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "0",
      "&:focus": {
        width: "360px",
        border: "1px solid #009BE2",
        borderRadius: "6px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&:focus": {
        width: "240px",
        borderRadius: "6px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      border: "1px solid #C9CDD5",
      borderRadius: "6px",
      "&:focus": {
        border: "1px solid #009BE2",
        borderRadius: "6px",
      },
    },
  },
}));

export default function PTSearchInput({
  onChangeSearchText,
  onKeyDown,
  value,
  activeSearchBar,
  onClearInput
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Search>
        <SearchIconWrapper>
          <img src={SearchIcon} alt="search" style={{ cursor: "pointer" }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search for company"
          inputProps={{ "aria-label": "search" }}
          onChange={onChangeSearchText}
          onKeyDown={onKeyDown}
          value={value}
        />
      </Search>
      {activeSearchBar && (
        <Close onClick={onClearInput} className={`${isSmallScreen && "mt-2"} ml-1 cursor-pointer`} />
      )}
    </>
  );
}
