import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import PTButton from "../../../components/GenericComponents/PTButton";
import "./projectModal.scss";
import CloseIcon from "@material-ui/icons/Close";

export default function ProjectModal({
  showProjectModal,
  setShowProjectModal,
}) {
  const [open, setOpen] = useState(true);

  const handleContactus = () => {
    window.location = "mailto:ksa@theproptechconnection.com";
  };

  const handleClose = () => {
    setOpen(false);
    setShowProjectModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "768px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="project-modal">
      <Modal
        open={showProjectModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="more-filter-modal modal"
      >
        <Box sx={style} className="text-center project-modal">
          <div>
            <IconButton className="close-btn" onClick={handleClose}>
              <CloseIcon alt="Icon" />
            </IconButton>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "left" }}
            >
              You are Just a step away! Contact us for full access
            </Typography>
            <p className="para-text m-auto text-left">
              Sorry, you do not have the right plan yet to access this
              information.
            </p>

            <p
              className="para-text m-auto text-left"
              style={{ paddingTop: 0, paddingBottom: 50 }}
            >
              Get in touch and find out how we can map your project, directly to
              the best technologies.
            </p>
          </div>

          <div className="flex justify-end footer-btn">
            <PTButton
              label="Back"
              className="normal-case fill-gray btn mr-4"
              size="large"
              handleOnClickEvent={() => handleClose()}
            />
            <PTButton
              label="Contact us"
              className="normal-case fill-blue btn"
              size="large"
              handleOnClickEvent={() => handleContactus()}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
