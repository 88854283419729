import {
  RFF_PROPTECH_LISTING,
  RFF_FORYOU,
  RFF_INVITE_API,
  RFF_WATCHLIST,
  RFF_PROPTECH_LISTING_HOME,
  RFF_PROJECTS,
} from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { Store } from "../../../../store/configureStore";
import { RFF_API } from "../../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { forYouDTO } from "../../dtos/forYouDTO";
import { getProptechDetailsAPI } from "../ProptechDetails";

export const getProptechs = async (
  filterFields,
  orderBy,
  order,
  page = 1,
  size = 10,
  search
) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
    ...(!!search ? { search } : {}),
  };
  return RFF_API()
    .get(`${RFF_PROPTECH_LISTING_HOME}`, { params })
    .then((res) => {
      if (res?.data) {
        if (res?.data?.data?.rows) {
          dispatch(Actions.RFFProptechs.RFFProptechsSet, res?.data?.data);
          return res?.data?.data;
        }
      }
    })
    .catch((err) => {
      handleError(err);
      return err?.status;
    });
};

export const getWatchlist = async (
  filterFields,
  orderBy,
  order,
  page = 1,
  size = 10
) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  };
  Store.dispatch({ type: Actions.RFFProptechs.RFFSetLoading, payload: true });
  return RFF_API()
    .get(RFF_WATCHLIST, { params })
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      if (res?.data) {
        if (res?.data?.data?.rows) {
          dispatch(Actions.RFFProptechs.RFFWatchlistSet, res?.data?.data);
          return res?.data?.data;
        }
      }
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      // handleError(err)
      return err?.status;
    });
};

export const setWatchlistStatus = async (
  id,
  tab,
  isFavorite,
  rffProptechs,
  rffForYou,
  activeMainTab
) => {
  Store.dispatch({ type: Actions.RFFProptechs.RFFSetLoading, payload: true });
  return RFF_API()
    .put(`${RFF_PROPTECH_LISTING}/${id}/favorites`, {
      isFavorite: !isFavorite,
      navigation:
        activeMainTab === 1 ? "Home" : activeMainTab === 2 ? "For you" : null,
    })
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleSuccess(res);
      if (res?.data?.code === 200) {
        if (tab === 0) {
          getProptechs(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffProptechs?.page + 1,
            rffProptechs?.size
          );
        } else if (tab === 1) {
          getForyou(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffForYou?.forYouPage + 1,
            rffForYou?.forYouPageSize
          );
        } else if (tab === 2) {
          getWatchlist();
        } else if (tab === "proptechDetails") {
          getProptechDetailsAPI(id);
          getProptechs(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffProptechs?.page + 1,
            rffProptechs?.size
          );
          getWatchlist();
        }
        return res?.data?.data;
      }
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleError(err);
      return err?.status;
    });
};

export const updateNote = async (id, note, tab, rffProptechs, rffForYou) => {
  Store.dispatch({ type: Actions.RFFProptechs.RFFSetLoading, payload: true });
  return RFF_API()
    .put(`${RFF_PROPTECH_LISTING}/${id}/notes`, { note: note })
    .then((res) => {
      handleSuccess(res);
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      if (res?.data?.data) {
        if (tab === 0) {
          getProptechs(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffProptechs?.page + 1,
            rffProptechs?.size
          );
        } else if (tab === 1) {
          getForyou(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffForYou?.forYouPage + 1,
            rffForYou?.forYouPageSize
          );
        } else if (tab === 2) {
          getWatchlist();
        } else if (tab === "proptechDetails") {
          getProptechDetailsAPI(id);
          getProptechs(
            rffProptechs?.filter,
            rffProptechs?.orderBy,
            rffProptechs?.order,
            rffProptechs?.page + 1,
            rffProptechs?.size
          );
          getWatchlist();
        }
        return res?.data?.data?.note;
      }
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleError(err);
      return err?.status;
    });
};

export const getForyou = async (
  filterFields,
  orderBy,
  order,
  page = 1,
  size = 10
) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  };
  dispatch(Actions.RFFProptechs.RFFSetLoading, true);
  return RFF_API()
    .get(RFF_FORYOU, { params })
    .then((res) => {
      dispatch(Actions.RFFProptechs.RFFSetLoading, false);
      if (res?.data && res?.data?.data?.rows) {
        dispatch(Actions.RFFForYou.RFFForyouSet, forYouDTO(res?.data?.data));
        return res?.data?.data;
      }
      handleError(res?.data);
    })
    .catch((err) => {
      dispatch(Actions.RFFProptechs.RFFSetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const inviteMember = async (email, message) => {
  dispatch(Actions.RFFProptechs.RFFSetLoading, true);
  return RFF_API()
    .post(`${RFF_INVITE_API}`, { email: email, message: message.trim() })
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.RFFProptechs.RFFSetLoading, false);
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleError(err);
      return err?.status;
    });
};

export const shareProptech = (proptechId, emailIds, message) => {
  let params = {
    rffUserIds: emailIds,
    message: message.trim(),
  };
  Store.dispatch({ type: Actions.RFFProptechs.RFFSetLoading, payload: true });
  return RFF_API()
    .put(`${RFF_PROPTECH_LISTING}/${proptechId}/share`, params)
    .then((res) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleSuccess(res);
      return res?.data?.data;
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      handleError(err);
      return err?.status;
    });
};

export const getProjectTabData = async (email, message) => {
  dispatch(Actions.RFFProptechs.RFFSetLoading, true);
  return RFF_API()
    .get(`${RFF_PROJECTS}/detail`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.RFFProptechs.RFFProjectTabData, {
        companyOtherDetails: res.data?.data?.companyOtherDetails,
        ...res.data?.data?.propjectOverviewDetails,
      });
      dispatch(Actions.RFFProptechs.RFFSetLoading, false);
    })
    .catch((err) => {
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetLoading,
        payload: false,
      });
      // handleError(err)
      return err?.status;
    });
};
