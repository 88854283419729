export default function rffForYou(
  initialState = {
    rffForYou: null,
    forYouPage: 0,
    forYouPageSize: 10,
  },
  action
) {
  switch (action.type) {
    case "RFF_FORYOU_LIST_SET":
      return {
        ...initialState,
        rffForYou: action.payload,
      };

    case "RFF_SET_PAGE_FORYOU":
      return {
        ...initialState,
        forYouPage: action.payload,
      };

    case "RFF_SET_PAGESIZE_FORYOU":
      return {
        ...initialState,
        forYouPageSize: action.payload,
      };

    case "RFF_SET_LOADING":
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case "RFF_RESET_LIST":
      return {
        ...initialState,
        rffForYou: null,
      };

    case "RFF_FORYOU_RESET":
      return {
        ...initialState,
        rffForYou: null,
        forYouPage: 0,
        forYouPageSize: 10,
      };

    default:
      return initialState;
  }
}
