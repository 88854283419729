export function navTabsTBDTO(data) {
    const unique = new Set(data.map((i) => i.navigation.id));
    let tabsData = data.reduce((acc, cur) => {
      if (unique.has(cur.navigation.id)) {
        const existing = acc.find(
          (obj) => obj.navigation.id === cur.navigation.id
        );
        if (existing) {
          existing.proptechTabs.push(cur.proptechTabs);
        } else {
          acc.push({
            navigation: { ...cur.navigation },
            proptechTabs: [cur.proptechTabs],
          });
        }
      }
      return acc;
    }, []);
    return tabsData.map((i) => {
      i.navigation = i.navigation.id;
      i.proptechTabs = i.proptechTabs.map((i) => i.id);
      return i;
    });
  }
  