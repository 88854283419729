import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import Chart from "../../Chart";
import { Radar_Chart_Labels } from "../../../constants";
import { designReviewChartLabels } from "../../../utils/chartData";

// Function for rating
const reviewBlock = (rating) => {
  let reviewBlockArr = [];
  for (let i = 1; i <= rating; i++) {
    reviewBlockArr.push(
      <li>
        <em className={`line${rating}`}></em>
      </li>
    );
  }
  for (let j = 0; j < 5 - rating; j++) {
    reviewBlockArr.push(
      <li>
        <em></em>
      </li>
    );
  }
  return reviewBlockArr;
};

export default function DesignReview() {
  // Store
  const { designReviewDetails } = useSelector(
    (state) => state.rffProptechDetails
  );

  const switchPositions = (arr) => {
    if (arr?.length <= 3) {
      return arr;
    }
    arr?.sort((a, b) => {
      let indexA = designReviewChartLabels.indexOf(a.placeRadarPoint.rada);
      let indexB = designReviewChartLabels.indexOf(b.placeRadarPoint.rada);

      return indexA - indexB;
    });

    return arr;
  };

  const checkRadarPointValue = (value) => {
    return value > 5 ? 5 : value;
  };

  return (
    <>
      {designReviewDetails ? (
        <div className="tabpanel-wrapper-place design-review-tab-1">
          <div className="left_col-place">
            <h2>Overview</h2>
            <div className="modal-design-1">
              <div className="market-growth">
                <div className="growth-info">
                  {designReviewDetails?.overview1 && (
                    <div className="growth-item">
                      <p>{designReviewDetails?.overview1}</p>
                    </div>
                  )}
                  {designReviewDetails?.overview2 && (
                    <div className="growth-item">
                      <p>{designReviewDetails?.overview2}</p>
                    </div>
                  )}
                  {designReviewDetails?.overview3 && (
                    <div className="growth-item">
                      <p>{designReviewDetails?.overview3}</p>
                    </div>
                  )}
                  {designReviewDetails?.overview4 && (
                    <div className="growth-item">
                      <p>{designReviewDetails?.overview4}</p>
                    </div>
                  )}
                  {designReviewDetails?.overview5 && (
                    <div className="growth-item">
                      <p>{designReviewDetails?.overview5}</p>
                    </div>
                  )}
                  {designReviewDetails?.noOverview === true && (
                    <div>
                      <span>No overview details provided.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right_col-place">
            <div className="flex items-start">
              <h2 className="pr-4">{Radar_Chart_Labels.techReview}</h2>
            </div>
            <div className="chart-wrapper mx-auto">
              <Chart
                labels={
                  designReviewDetails?.placeRadars?.length
                    ? switchPositions(designReviewDetails?.placeRadars)?.map(
                        (i) => i.placeRadarPoint?.rada?.split(" ")
                      )
                    : designReviewChartLabels
                }
                items={
                  designReviewDetails?.placeRadars?.length
                    ? switchPositions(designReviewDetails?.placeRadars)?.map(
                        (i) => checkRadarPointValue(i.value)
                      )
                    : []
                }
                step={1}
                label={"Rating"}
                maximumValue={5}
              />
            </div>
          </div>
          <div className="design-key-points">
            <div className="market-growth key-metrics-1">
              <div className="modal-design-1">
                <div className="growth-info">
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Architecture</h3>
                      <p className="small-text">
                        How does brand and aesthethic of the application as a
                        hold up as a whole? Checking consistency of UI, layout
                        and actions.{" "}
                      </p>
                      <div className="rating-process-wrapper">
                        <span>
                          {designReviewDetails?.radarDetails?.architecture}
                        </span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.architecture
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.visualDesignBrandingText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Communication</h3>
                      <p className="small-text">
                        How easily can a new user can use the platform and how
                        familiar actions are compared to common experiences
                        across other applications?
                      </p>
                      <div className="rating-process-wrapper">
                        <span>
                          {designReviewDetails?.radarDetails?.communication}
                        </span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.communication
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.intuitivenessText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Search</h3>
                      <p className="small-text">
                        How is the platform to navigate for both novice and
                        expert users. Does the platform provide shortcuts or
                        methods to get from point A to point B in an effective
                        manner, and is this shown in a logical or natural order?
                      </p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.radarDetails?.search}</span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.search
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.efficiencyText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Storage</h3>
                      <p className="small-text">
                        How well does the application provide indications that
                        the user correctly completed an application? Are the
                        actions predictable for the user and how well can they
                        determine their next step?
                      </p>
                      <div className="rating-process-wrapper">
                        <span>
                          {designReviewDetails?.radarDetails?.storage}
                        </span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.storage
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.feedbackText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Processing</h3>
                      <p className="small-text">
                        How well can the user interact with application and be
                        safe from making errors? How well can they recovery from
                        errors they have made?
                      </p>
                      <div className="rating-process-wrapper">
                        <span>
                          {designReviewDetails?.radarDetails?.processing}
                        </span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.processing
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.errorPreventionRecoveryText}</p>
                    </div>
                  </div>
                  <div className="growth-item-wrap">
                    <div className="growth-item">
                      <h3>Power</h3>
                      <p className="small-text">
                        How well does the platform support a user should they
                        require assistance. What level of documentation and
                        information is available for the user and how easily is
                        it accessed?
                      </p>
                      <div className="rating-process-wrapper">
                        <span>{designReviewDetails?.radarDetails?.power}</span>
                        <ul>
                          {reviewBlock(
                            designReviewDetails?.radarDetails?.power
                          )}
                        </ul>
                      </div>
                      <p>{designReviewDetails?.supportText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
