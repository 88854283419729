import { Actions } from "../../store/actions"
import { Store } from "../../store/configureStore"

export const startLoading = () => {
  Store.dispatch({ type: Actions.Loader.LoadingStart });
}

export const stopLoading = () => {
  Store.dispatch({ type: Actions.Loader.LoadingStop });
}
