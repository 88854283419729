import React from "react";
import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import "./PTButton.scss";

//Generic Button component
export default function PTButton(props) {
	//  ***** props *****
	const {
		variant,
		label,
		disabled,
		handleOnClickEvent,
		size,
		color,
		startIcon,
		endIcon,
		className,
		type,
		loading,
		id,
	} = props;

	return (
		<Button
			id={id}
			variant={variant}
			disabled={disabled}
			size={size}
			type={type}
			className={className ? className : null}
			color={color}
			onClick={handleOnClickEvent ? handleOnClickEvent : null}
			startIcon={startIcon ? startIcon : null}
			endIcon={endIcon ? endIcon : null}
		>
			{label}
			{loading ? (
				<CircularProgress
					size={20}
					style={{ color: "#0f2e29", marginLeft: "12px" }}
				/>
			) : null}
		</Button>
	);
}

// Default PROPS
PTButton.defaultProps = {
	variant: "contained",
	label: "Submit",
	disabled: false,
	size: "medium",
	color: "secondary",
	loading: false,
};

// Expected PROPS
PTButton.prototypes = {
	variant: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	size: PropTypes.string,
	color: PropTypes.string,
	type: PropTypes.string,
};
