import axios from "axios";
import { configs } from "../../constants/Configs";
import { getRFFAccessToken } from "../session";

export const RFF_API = () => {
  return axios.create({
    baseURL: "",
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRFFAccessToken(),
    },
  });
};

export const CALENDLY_API = () => {
  return axios.create({
    baseURL: "",
    timeout: 30000,
    headers: { Authorization: "Bearer " + configs.calendlyToken },
  });
};

export const PUBLIC_API = () => {
  return axios.create({
    baseURL: "",
    timeout: 30000,
  });
};
