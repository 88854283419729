import { Box, IconButton, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import { VideoCardModal } from "../../constants/General/styles";
import closeIcon from '../../assets/Icons/ic-cross.svg';

export default function VideoModal({ videoUrl, closeVideoModal, openVideoModal}) {
    
  return (
    <Modal open={openVideoModal} onClose={closeVideoModal} className="watch-video-modal">
      <Box sx={VideoCardModal}>
        <IconButton className="close-btn" onClick={closeVideoModal}>
          <img src={closeIcon} alt="Icon" />
        </IconButton>
        <ReactPlayer controls pip={false} url={videoUrl} />
      </Box>
    </Modal>
  );
}
