import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import PTButton from "../../../components/GenericComponents/PTButton";
import PTInput from "../../../components/GenericComponents/PTInput";
import LeftLogo from "../../../components/LeftLogo";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { startLoading, stopLoading } from "../../../utils/utility/loader";
import { RFF_FORGOT_PASSWORD_API } from "../../../constants/APIs";
import "./forgotPassword.scss";

export default function ForgotPassword() {
  const initialValues = {
    email: "",
    password: "",
    captcha: "",
  };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("This email is not a valid format.")
        .required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      sendForgotPasswordLink(
        {
          email: values.email,
        },
        resetForm
      );
    },
  });

  const sendForgotPasswordLink = (payload, resetForm) => {
    startLoading();
    axios
      .post(RFF_FORGOT_PASSWORD_API, payload)
      .then((res) => {
        stopLoading();
        resetForm({ values: "" });
        handleSuccess(res);
        navigate("/checkemail", { replace: true });
      })
      .catch((error) => {
        stopLoading();
        handleError(error);
      });
  };

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="text-wrapper">
            <h2>Reset your password</h2>
            <p className="p-0">
              We’ll email you a secure link to change your password.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-wrapper">
              <div
                className={
                  "form-group " +
                  (formik.touched.email && formik.errors.email
                    ? "error-parent"
                    : "") +
                  (!formik.errors.email && formik.values.email
                    ? "success-attempt"
                    : "")
                }
              >
                <PTInput
                  id="email"
                  name="email"
                  placeholder="Email"
                  label="Email"
                  fullWidth
                  className="form-control"
                  handleOnChangeEvent={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="error-txt">{formik.errors.email}</span>
                )}
              </div>
              <PTButton
                label="Send reset link"
                type="submit"
                className="normal-case mt-[13px] fill-blue btn"
                size="large"
              />
            </div>
          </form>
          <div className="not-have-account">
            <span>
              Remembered your password?
              <span
                className="cursor-pointer block"
                onClick={() => (window.location.href = "/login")}
              >
                Back to login
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
