import React from "react";
import { useNavigate } from "react-router-dom";
// import PlaceLogo from "../../assets/Icons/place-logo.svg";
import PlaceLogo from "../../assets/Images/PLACE_White logo.png";
import RFFLogo from "../../assets/Images/PIF-white-logo.svg";
import "./style.css";

export default function LeftLogo() {
	const navigate = useNavigate();

	return (
		<>
			<div className={`background-blue background-place md:min-h-screen`}>
				<img
					src={PlaceLogo}
					alt=""
					className="cursor-pointer z-10"
					onClick={() => navigate("/", { replace: true })}
				/>
				<div className="line"> </div>
				<img
					src={RFFLogo}
					alt="RFF"
					className="cursor-pointer z-11 rffLogo"
					onClick={() => navigate("/", { replace: true })}
				/>
			</div>
		</>
	);
}
