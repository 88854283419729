import React from "react";
import { useSelector } from "react-redux";
import { Actions } from "../../../store/actions";
import { dispatch } from "../../../utils/utility/store";
import Pagination from "../../components/Pagination";
import ProptechCard from "../../components/ProtechCard";

export default function ForYouTab({ tab }) {
  const { rffForYou, forYouPage, forYouPageSize } = useSelector(
    (state) => state.rffForYou
  );

  // Set page no. in store on page change in pagination
  const handlePageChange = (v) => {
    dispatch(Actions.RFFForYou.RFFSetPage, v);
  };

  // Set page no. and size in store on page change in pagination
  const handleRowChange = (v) => {
    dispatch(Actions.RFFForYou.RFFSetPageSize, v);
    dispatch(Actions.RFFForYou.RFFSetPage, 0);
  };

  return (
    <>
      <div className="no-print proptech-card-wrapper">
        {/* Proptech card */}
        {rffForYou?.rows && rffForYou?.rows.length
          ? rffForYou?.rows.map((proptech) => {
              return (
                <ProptechCard
                  className="for-you-card"
                  proptech={proptech}
                  key={proptech?.id}
                  tab={tab}
                />
              );
            })
          : null}
      </div>
      {/* Pagination */}
      <div className="flex justify-between items-center">
        {rffForYou?.count ? (
          <span className="text-sm font-normal">All values in USD</span>
        ) : null}
        {rffForYou?.count ? (
          <Pagination
            length={rffForYou?.count}
            tab="Proptechs"
            page={forYouPage}
            size={forYouPageSize}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
        ) : null}
      </div>
    </>
  );
}
