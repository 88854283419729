export const ChangePasswordModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

export const ChangePasswordFooter = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "28px",
};

export const EditTagModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

export const NewsfeedModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
export const VideoCardModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
};

export const flexContainerSx = {
  width: "100%",
  height: "320px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ManageUsersModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  minHeight: 440,
  th: {
    fontFamily: "Poppins, sans-serif",
  },
};

export const TableContainerSx = { height: "320px" };
export const TableRowSx = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};
