import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ProptechCard from "../../components/ProtechCard";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import Pagination from "../../components/Pagination";
import { dispatch } from "../../../utils/utility/store";

export default function WatchlistTab({ tab }) {
  // Store
  const { rffWatchList, rffWatchListPage, rffWatchListPageSize } = useSelector(
    (state) => state.rffProptechs
  );

  useEffect(() => {
    Store.dispatch({
      type: Actions.RFFProptechDetails.RFFProptechDetailsReset,
    });
  }, []);

  const handlePageChange = (v) => {
    dispatch(Actions.RFFProptechs.RFFWatchlistPageSet, v);
    // setHomeCount(1)
  };

  const handleRowChange = (v) => {
    dispatch(Actions.RFFProptechs.RFFWatchlistPageSizeSet, v);
    dispatch(Actions.RFFProptechs.RFFWatchlistPageSet, 0);
    // setHomeCount(1)
  };

  return (
    <>
      <div className="no-print proptech-card-wrapper watchlist-card">
        {rffWatchList?.rows && rffWatchList?.rows.length
          ? rffWatchList?.rows.map((proptech) => {
              return (
                <ProptechCard
                  proptech={proptech}
                  key={proptech?.id}
                  tab={tab}
                />
              );
            })
          : null}
      </div>
      <div className="flex justify-between items-center">
        {rffWatchList?.count ? (
          <span className="text-sm font-normal">All values in USD</span>
        ) : null}
        {rffWatchList?.count ? (
          <Pagination
            length={rffWatchList?.count}
            tab="Proptechs"
            page={rffWatchListPage}
            size={rffWatchListPageSize}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
        ) : null}
      </div>
    </>
  );
}
